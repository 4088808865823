import {html, LitElement} from 'lit-element';
import {repeat} from 'lit-html/directives/repeat.js';
import {ScanHistoryStore} from '../../src/service/scan-history-store.js';

import styles from '../scan-item/scan-item.lit-scss';
import buttonStyles from '../button/button.lit-scss';

class ScanHistory extends LitElement {
    static get properties() {
        return {
            items: {type: Array, attribute: true},
        };
    }

    static get styles() {
        return [styles, buttonStyles];
    }

    constructor() {
        super();
        this.scanHistoryStore = new ScanHistoryStore();
        this.items = this.scanHistoryStore.get('history');

        this.attachListeners();
    }

    attachListeners() {
        document.addEventListener('addPrinted', e => this.updatePrinted(e));
    }

    updatePrinted() {
        this.items = [...this.scanHistoryStore.get('history')];
    }

    addProduct(product) {
        this.scanHistoryStore.add('history', product);
        this.items = [...this.scanHistoryStore.get('history')];
    }

    render() {
        if (this.items === null) {
            return html`
                <p>Je hebt nog geen producten gescand.</p>
            `;
        }

        const currentItems = this.items.slice(0, 3);

        return html`
            ${repeat(currentItems, item => html`
                <div class="scan-item" data-component="scan-item/scan-item" data-product-sku="${item[1].sku}">
                    <img src="${item[1].image}" alt="${item[1].image}">
                    <div class="scan-item__content">
                        <div>
                            <span class="scan-item__content__title">${item[1].title}</span>
                            <span>${item[1].sku}</span>
                            <span>EAN: ${item[1].ean}</span>
                            <span>Reeds geprint: ${item[1].printed}</span>
                        </div>
                        <div class="scan-item__print">
                            <div class="scan-item__print__count">
                                <span @click="${this.minusClicked}" class="scan-item__print__action minus">-</span>
                                <input class="scan-item__print__input" type="text" value="${item[1].quantity}">
                                <span @click="${this.plusClicked}" class="scan-item__print__action plus">+</span>
                            </div>
                            <button @click="${this.printClicked}" class="button" type="submit">Print</button>
                        </div>
                    </div>
                </div>
            `)}
        `;
    }

    plusClicked(event) {
        const currentQuantity = event.target.closest('.scan-item__print__count').querySelector('input').value;

        if (currentQuantity < 99) {
            event.target.closest('.scan-item__print__count').querySelector('input').value++;
        }
    }

    minusClicked(event) {
        const currentQuantity = event.target.closest('.scan-item__print__count').querySelector('input').value;

        if (currentQuantity > 1) {
            event.target.closest('.scan-item__print__count').querySelector('input').value--;
        }
    }

    printClicked(event) {
        const products = new Map(this.scanHistoryStore.get('history'));
        let product = products.get(event.target.closest('[data-product-sku]').dataset.productSku);

        const data = {
            quantity: event.target.closest('.scan-item__print').querySelector('input').value,
            pdf: product.pdf,
            title: product.title,
            sku: product.sku,
        };

        const customEvent = new CustomEvent('print', {
            detail: data,
        });

        document.dispatchEvent(customEvent);

        event.target.closest('.scan-item__print').querySelector('input').value = '1';
        this.items = [...this.scanHistoryStore.get('history')];
    }
}
customElements.define('scan-history', ScanHistory);
