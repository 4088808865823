export class PrintJob {
    #title;

    #pdf;

    #status;

    #sku;

    constructor(title, pdf, sku) {
        this.#title = title;
        this.#pdf = pdf;
        this.#sku = sku;
    }

    get title() {
        return this.#title;
    }

    set title(value) {
        this.#title = value;
    }

    get pdf() {
        return this.#pdf;
    }

    set pdf(value) {
        this.#pdf = value;
    }

    get status() {
        return this.#status;
    }

    set status(value) {
        this.#status = value;
    }

    get sku() {
        return this.#sku;
    }

    set sku(value) {
        this.#sku = value;
    }
}
