import {PrintQueueStore} from './print-queue-store.js';
import {PrintJob} from './print-job.js';

export class PrintQueueManager {
    /**
     * @type {PreferenceStore}
     */
    preferenceStore;

    /**
     * @type {PrintQueueStore}
     */
    printQueueStore;

    /**
     * @type {Boolean}
     */
    isPrinting

    /**
     * @type {Array}
     */
    data;

    /**
     * @type {Map}
     */
    pendingJobs = new Map();

    /**
     * @param {PreferenceStore} preferenceStore
     */
    constructor(preferenceStore) {
        this.preferenceStore = preferenceStore;
        this.printQueueStore = new PrintQueueStore();

        if (this.printQueueStore.get('queue') === null) {
            this.data = [];
        } else {
            this.data = this.printQueueStore.get('queue');
        }

        this.rear = this.data.length;

        this.attachListeners();
    }

    attachListeners() {
        document.addEventListener('print', e => this.addToQueue(e));
        window.printing.addEventListener('onJobStatusChanged', e => this.onJobStatusChanged(e));
    }

    onJobStatusChanged(event) {
        if (!this.pendingJobs.has(event.detail.jobId)) {
            return;
        }

        if (event.detail.jobStatus === 'PRINTED') {
            const printJob = this.pendingJobs.get(event.detail.jobId);
            const customEvent = new CustomEvent('addPrinted', {
                detail: printJob.sku,
            });
            document.dispatchEvent(customEvent);

            this.pendingJobs.delete(event.detail.jobId);
            return;
        }

        if (event.detail.jobStatus === 'FAILED' || event.detail.jobStatus === 'CANCELED') {
            const printJob = this.pendingJobs.get(event.detail.jobId);

            const data = {
                printJob: printJob,
            };

            const customEvent = new CustomEvent('failedMessage', {
                detail: data,
            });

            document.dispatchEvent(customEvent);

            this.pendingJobs.delete(event.detail.jobId);
        }
    }

    addToQueue(event) {
        for (let i = 0; i < event.detail.quantity; i++) {
            this.enqueue(new PrintJob(event.detail.title, event.detail.pdf, event.detail.sku));
        }
        this.print();
    }

    enqueue(element) {
        this.data.push(element);
        this.printQueueStore.add('queue', this.data);
    }

    isEmpty() {
        return this.data.length === 0;
    }

    dequeue() {
        if (this.isEmpty() === false) {
            const value = this.data.shift();
            this.printQueueStore.add('queue', this.data);
            return value;
        }
        return null;
    }

    async print() {
        if (this.isPrinting) {
            return;
        }

        this.isPrinting = true;

        while (this.data.length > 0) {
            const printJob = this.dequeue();
            // eslint-disable-next-line no-await-in-loop
            const result = await this.printJob(printJob.pdf);

            if (result.status === 'USER_REJECTED') {
                this.data.unshift(printJob);
                this.printQueueStore.add('queue', this.data);
            }
            if (result.status === 'OK') {
                this.pendingJobs.set(result.jobId, printJob);
            }
        }
        this.isPrinting = false;
    }

    /**
     * @param {String} pdf
     * @return {Promise}
     */
    printJob(pdf) {
        /* eslint-disable camelcase */
        const jobOptions = {
            color: {type: 'STANDARD_MONOCHROME'},
            duplex: {type: 'NO_DUPLEX'},
            copies: {copies: 1},
            dpi: {horizontal_dpi: 300, vertical_dpi: 300},
            collate: {collate: false},
            page_orientation: {type: 'LANDSCAPE'},
            media_size: {
                height_microns: 25400,
                vendor_id: 'oe_w108h72_1.5x1in',
                width_microns: 38100,
            },
        };
        /* eslint-enable */

        return window.printing.submitJob(
            this.preferenceStore.get('printerId'),
            'test',
            pdf,
            jobOptions
        );
    }
}
