export class PrintQueueStore {
    add(prefName, value) {
        localStorage.setItem(this.buildPrefName(prefName), JSON.stringify((Array.from(value))));
    }

    get(prefName) {
        return JSON.parse(localStorage.getItem(this.buildPrefName(prefName)));
    }

    buildPrefName(prefName) {
        return 'printQueueStore' + prefName;
    }
}
