import {PrinterSelectElement} from './printer-select-element';

export class OverlayElement {
    options = {
        printerSelectSelector: '[data-element-printer-select]',
    };

    /**
     * @type {PreferenceStore}
     */
    preferenceStore;

    /**
     * @type {HTMLDialogElement}
     */
    element;

    /**
     * @type {PrinterSelectElement}
     */
    printerSelectElement;

    /**
     * @param {HTMLDialogElement} element
     * @param {PreferenceStore} preferenceStore
     */
    constructor(element, preferenceStore) {
        this.preferenceStore = preferenceStore;
        this.element = element;
        this.printerSelectElement = new PrinterSelectElement(
            this.element.querySelector(this.options.printerSelectSelector),
            this.preferenceStore
        );

        this.attachListeners();
    }

    show() {
        this.element.show();
    }

    close() {
        this.element.close();
    }

    setPrinters(printers) {
        this.printerSelectElement.setPrinters(printers);
        const printerId = this.preferenceStore.get('printerId');
        if (printerId === null || printerId === '') {
            this.show();
        }
    }

    setSelectedPrinter(printerId) {
        this.printerSelectElement.setSelectedPrinter(printerId);
    }

    attachListeners() {
        this.element.addEventListener('change', (e) => {
            this.preferenceStore.add('printerId', e.target.value);
            this.element.close();
            this.printerSelectElement.setSelectedPrinter(e.target.value);
        });
    }
}
