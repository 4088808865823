export class ScanHistoryStore {
    /**
     * @type {Map}
     */
    history;

    constructor() {
        if (this.get('history') === null) {
            this.history = new Map();
        } else {
            this.history = new Map(this.get('history'));
        }

        this.attachListeners();
    }

    attachListeners() {
        document.addEventListener('addPrinted', e => this.addPrinted('history', e));
    }

    /**
     * @param {String} prefName
     * @param {Object} product
     * @param {String} product.sku
     * @param {Promise} product.pdf
     * @param {String} product.ean
     * @param {String} product.title
     * @param {String} product.image
     * @param {Number} product.quantity
     * @param {Number} product.printed
     */
    add(prefName, product) {
        const tempHistory = new Map();
        if (typeof this.history.get(product.sku) !== 'undefined') {
            let currentProduct = this.history.get(product.sku);
            currentProduct.pdf = product.pdf;
            tempHistory.set(product.sku, currentProduct);
        } else {
            tempHistory.set(product.sku, product);
        }
        this.history = new Map([...tempHistory, ...this.history]);
        localStorage.setItem(this.buildPrefName(prefName), JSON.stringify(Array.from(this.history.entries())));
    }

    addPrinted(prefName, event) {
        const sku = event.detail;
        const tempHistory = new Map();
        if (typeof this.history.get(sku) !== 'undefined') {
            let currentProduct = this.history.get(sku);
            currentProduct.printed += 1;
            this.history.set(sku, currentProduct);
            tempHistory.set(sku, currentProduct);
        }
        this.history = new Map([...tempHistory, ...this.history]);
        localStorage.setItem(this.buildPrefName(prefName), JSON.stringify(Array.from(this.history.entries())));
    }

    get(prefName) {
        return JSON.parse(localStorage.getItem(this.buildPrefName(prefName)));
    }

    buildPrefName(prefName) {
        return 'scanHistoryStore' + prefName;
    }
}
