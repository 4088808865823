import {AutocompleteFormElement} from './element/autocomplete-form-element.js';
import {PrinterSelectElement} from './element/printer-select-element.js';
import {PreferenceStore} from './service/preference-store';
import {OverlayElement} from './element/overlay-element';
import {AutocompleteElement} from './element/autocomplete-element.js';
import '../components/scan-history/scan-history.js';
import '../components/message/message.js';
import {PrintQueueManager} from './service/print-queue-manager.js';

export class LabelScanUi {
    options = {
        formSelector: '[data-element-autocomplete-form]',
        autocompleteSelector: '[data-element-autocomplete]',
        printerSelectSelector: '[data-element-printer-select]',
        printerBlockSelector: '[data-element-printer-block]',
        overlaySelector: '[data-element-overlay]',
    };

    /**
     * @type {HTMLElement}
     */
    element;

    /**
     * @type {AutocompleteFormElement}
     */
    formElement;

    /**
     * @type {AutocompleteElement}
     */
    autocompleteElement;

    /**
     * @type {PrinterSelectElement}
     */
    printerSelectElement;

    /**
     * @type {PrinterBlockElement}
     */
    printerBlockElement;

    /**
     * @type {PreferenceStore}
     */
    preferenceStore;

    /**
     * @type {OverlayElement}
     */
    overlayElement

    constructor(element) {
        this.preferenceStore = new PreferenceStore();

        this.element = element;
    }

    async setup() {
        this.setupElements();

        // Wait for the extension to inject the custom printing API.
        await this.waitForExtension();
        this.printQueue = new PrintQueueManager(this.preferenceStore);
        const printers = await this.loadPrinters();
        this.overlayElement.setPrinters(printers);
        this.printerSelectElement.setPrinters(printers);
        const printerId = this.preferenceStore.get('printerId');
        if (printerId !== null && printerId !== '') {
            this.printerSelectElement.setSelectedPrinter(printerId);
            this.overlayElement.close();
            await this.printQueue.print();
        }
    }

    setupElements() {
        const overlayElement = this.element.querySelector(this.options.overlaySelector);
        this.overlayElement = new OverlayElement(overlayElement, this.preferenceStore);
        this.overlayElement.show();
        overlayElement.addEventListener('change', (e) => {
            this.preferenceStore.add('printerId', e.target.value);
            this.overlayElement.close();
            this.printerSelectElement.setSelectedPrinter(e.target.value);
        });

        const formElement = this.element.querySelector(this.options.formSelector);
        if (formElement === null) {
            throw new Error('Form element was not found in the DOM');
        }
        this.formElement = new AutocompleteFormElement(formElement);

        const autocompleteElement = this.element.querySelector(this.options.autocompleteSelector);
        if (autocompleteElement === null) {
            throw new Error('Autocomplete element was not found in the DOM');
        }
        this.autocompleteElement = new AutocompleteElement(
            autocompleteElement
        );

        const printerSelectElement = this.element.querySelector(this.options.printerSelectSelector);
        if (printerSelectElement === null) {
            throw new Error('Printer select element was not found in the DOM');
        }
        this.printerSelectElement = new PrinterSelectElement(printerSelectElement, this.preferenceStore);

        /**
         * const printerBlockElement = this.element.querySelector(this.options.printerBlockSelector);
         * if (printerBlockElement === null) {
         *     throw new Error('Printer block element was not found in the DOM');
         * }
         * this.printerBlockElement = new PrinterBlockElement(printerBlockElement);
         */
    }

    waitForExtension() {
        if (typeof window.printing !== 'undefined') {
            return Promise.resolve();
        }

        return new Promise(resolve => {
            window.addEventListener('printingApiInitialized', () => {
                resolve();

                // TODO: Reject after x seconds? Extensions load very quickly or not at all.
            }, {once: true});
        });
    }

    loadPrinters() {
        return window.printing.getPrinters();
    }
}
