export class AutocompleteElement {
    /**
     * @type{HTMLElement}
     */
    element;

    /**
     * @param {HTMLElement} element
     */
    constructor(element) {
        this.element = element;
        this.attachListeners();
    }

    attachListeners() {
        this.element.addEventListener('click', e => this.onItemSelect(e));
    }

    fetchPdf(uri) {
        return new Promise(async (resolve, reject) => {
            const pdf = await fetch(uri);

            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(await pdf.blob());
        });
    }

    async onItemSelect(event) {
        if (event.target.closest('[data-product-pdf-uri]') !== null) {
            this.element.style.display = 'none';
            document.querySelector('[data-element-autocomplete-form]').classList.remove('-has-results');
            document.querySelector('[data-element-autocomplete-form]')
                .querySelector('input').value = '';

            const closestDataset = event.target.closest('[data-product-sku]').dataset;

            const product = {
                sku: closestDataset.productSku,
                pdf: await this.fetchPdf(closestDataset.productPdfUri),
                ean: closestDataset.productEan,
                title: closestDataset.productTitle,
                image: closestDataset.productImage,
                quantity: 1,
                printed: 0,
            };

            const data = {
                quantity: product.quantity,
                pdf: product.pdf,
                title: product.title,
                sku: product.sku,
            };

            const customEvent = new CustomEvent('print', {
                detail: data,
            });

            document.dispatchEvent(customEvent);

            document.querySelector('scan-history').addProduct(product);
        }
    }
}
