import {html, LitElement} from 'lit-element';
import {repeat} from 'lit-html/directives/repeat.js';
import close from '../images/close.svg';
import styles from './message.lit-scss';

class Message extends LitElement {
    static get properties() {
        return {
            items: {type: Array, attribute: true},
        };
    }

    static get styles() {
        return styles;
    }

    constructor() {
        super();
        this.items = [];

        this.attachListeners();
    }

    attachListeners() {
        document.addEventListener('failedMessage', e => this.addFailedMessage(e));
    }

    addFailedMessage(event) {
        this.items.push(event.detail.printJob.title);
        this.deleteMessage();
        this.items = [...this.items];
    }

    render() {
        if (this.items.length === 0) {
            return null;
        }

        return html`
            <div class="message-wrapper">
                ${repeat(this.items, (item, index) => html`
                    <div class="message -error" data-component="message/message" data-index="${index}">
                        <p>Printjob mislukt voor ${item}</p>
                        <button @click="${this.closeMessage}" class="message__close">
                            <img src="${close}" alt="close message">
                        </button>
                    </div>
                `)}
            </div>
            `;
    }

    deleteMessage() {
        if (this.items.length > 0) {
            setTimeout(() => {
                this.items.splice(0, 1);
                this.items = [...this.items];
                this.deleteMessage();
            }, 8000);
        }
    }

    closeMessage(event) {
        this.items.splice(parseInt(event.target.closest('[data-index]').dataset.index), 1);
        this.items = [...this.items];
    }
}
customElements.define('pop-up-message', Message);
