export class PrinterSelectElement {
    /**
     * @type {HTMLElement}
     */
    element;

    /**
     * @type {PreferenceStore}
     */
    preferenceStore;

    /**
     * @param {HTMLElement} element
     * @param {PreferenceStore} preferenceStore
     */
    constructor(element, preferenceStore) {
        this.preferenceStore = preferenceStore;
        this.element = element;

        this.attachListeners();
    }

    setPrinters(printers) {
        const firstOpt = document.createElement('option');
        firstOpt.innerHTML = '';
        this.element.append(firstOpt);
        for (let printer of printers) {
            const opt = document.createElement('option');
            opt.value = printer.id;
            opt.innerHTML = printer.name;
            this.element.append(opt);
        }

        const printerId = this.preferenceStore.get('printerId');
        if (printerId !== null && printerId !== '') {
            this.setSelectedPrinter(printerId);
        }
    }

    setSelectedPrinter(printerId) {
        this.element.value = printerId;
    }

    attachListeners() {
        this.element.addEventListener('change', (e) => {
            this.preferenceStore.add('printerId', e.target.value);
        });
    }
}
