export class PreferenceStore {
    add(prefName, value) {
        localStorage.setItem(this.buildPrefName(prefName), value);
    }

    get(prefName) {
        return localStorage.getItem(this.buildPrefName(prefName));
    }

    buildPrefName(prefName) {
        return 'preferenceStore' + prefName;
    }
}
