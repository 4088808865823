export class AutocompleteFormElement {
    /**
     * @type {HTMLFormElement}
     */
    element;

    timeoutId;

    constructor(element) {
        this.element = element;
        this.abortController = null;

        this.attachListeners();
        document.querySelector('[data-element-autocomplete]').style.display = 'none';
    }

    attachListeners() {
        this.element.addEventListener('keyup', (e) => {
            if (this.abortController !== null) {
                this.abortController.abort();
            }
            this.abortController = new AbortController();
            if (e.target.value === '' || e.target.value.length < 4) {
                document.querySelector('[data-element-autocomplete]').style.display = 'none';
                this.element.classList.remove('-has-results');
            } else if (e.target.value.length > 3) {
                this.element.classList.add('-has-results');
                document.querySelector('[data-element-autocomplete]').style.display = 'block';
                this.fetchResults(e.target.name, e.target.value);
            }
        });

        this.element.addEventListener('submit', (e) => {
            e.preventDefault();
        });
    }

    fetchResults(name, value) {
        document.querySelector('[data-element-autocomplete]').innerHTML = '';
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.append(name, value);
        let request = new Request(this.element.getAttribute('action') + '?' + urlSearchParams.toString());

        if (this.timeoutId !== null) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }

        this.timeoutId = setTimeout(() => {
            fetch(request, {signal: this.abortController.signal})
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.text();
                })
                .then((response) => {
                    document.querySelector('[data-element-autocomplete]').innerHTML = response;
                });
        }, 250);
    }
}
